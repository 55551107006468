.showMoreButton {
  margin: auto;
  display: block;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.scrollToTopButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 30px;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 11111111111111;
}
