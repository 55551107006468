.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-width: 100%;
  max-height: 90vh;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 100;
  padding: 15px;
}

.close-button img {
  width: 25px;
  height: 25px;
}

.override-custom-container {
  text-align: center;
  margin-bottom: 10px;
  padding: 15px 0;
}

.modal-body {
  padding: 20px;
}

h2 {
  margin-top: 0;
}

p {
  margin: 0;
}

@media (max-width: 426px) {
  .modal-content {
    width: 90%;
  }

  p {
    font-size: 13px;
  }

  .modal-body {
    padding: 0;
  }

  .override-custom-container {
    border-radius: 10px;
    padding: 8px 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .close-button img {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }
}
