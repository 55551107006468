.busLineDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1.5px solid;
}

.imgBusLineCard {
  width: 50px;
  height: auto;
}

.busLineText {
  display: flex;
  flex-direction: column;
}

.lineTitle {
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

.lineDescription {
  text-align: left;
  width: 100%;
  color: grey;
  font-size: 15px;
}

.blinkingImageLinePage {
  animation: blink 1s infinite;
  width: 65px;
  height: 22px;
  margin-left: 10px;
  margin-bottom: 2px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 425px) {
  .imgBusLineCard {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .lineDescription {
    text-align: left;
    width: 100%;
    color: #666;
  }

  .busLineDetailsContainer {
    padding: 15px 15px;
    display: grid;
    grid-template-columns: 45px 1fr;
    border: 1px solid;
  }

  .lineDescription {
    font-size: 12px;
  }

  .lineTitle {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .blinkingImageLinePage {
    width: 50px;
    height: 15px;
    margin-left: 10px;
    margin-bottom: 2px;
  }
}
