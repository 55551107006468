.FullTimeModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* overflow: auto;  */
}

.FullTimeModalContent {
  max-width: 100%;
  max-height: 90vh;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  overflow-y: auto;
}

.FullTimeModalContent::-webkit-scrollbar {
  display: none;
}

.FullTimeModalCloseButton {
  position: absolute;
  top: 2px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 100;
  padding: 10px;
}

.FullTimeModalCloseButton img {
  width: 25px;
  height: 25px;
  margin: 2px;
}

.FullTimeModalModalBody {
  padding: 20px;
}

.FullTimeModalTimeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  gap: 10px;
  margin-top: 20px;
}

.FullTimeModalTimeBox {
  padding: 8px 2px;
  border-radius: 10px;
  text-align: center;
  font-size: 1rem;
}

h2 {
  margin-top: 0;
}

p {
  margin: 0;
}

@media (max-width: 426px) {
  .FullTimeModalContent {
    width: 90%;
  }

  p {
    font-size: 13px;
  }

  .FullTimeModalModalBody {
    padding: 15px 0px;
  }

  .FullTimeModalTimeBox {
    padding: 2px 10px;
  }

  .FullTimeModalCloseButton img {
    width: 20px;
    height: 20px;
  }
}
