/* .leftSiteAboutUs {
    position: relative;
    width: 70%;
    left: 0;
    border-radius: 37px;
    text-align: left;
}

.rightSiteAboutUs {
    position: relative;
    width: 70%;
    right: 0;
    border-radius: 37px;
    text-align: right;
} */

/* .aboutUsContainer {
    position: relative;
    margin-top: 90px;
  }
  
  .headerAboutUs {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .leftSiteAboutUs,
  .rightSiteAboutUs {
    position: absolute;
    top: 0;
    width: 70%;
    padding: 20px;
  }
  
  .leftSiteAboutUs {
    left: 0;
  }
  
  .rightSiteAboutUs {
    top: 200px;
    right: 0;
  } */

  /* AboutUsStyles.css */

.mainContent {
  width: 100%;
}

.aboutUsContainer {
  min-height: 65vh;
  margin-top: 90px;
  padding: 25px 0;
}
  
.headerAboutUs {
  text-align: center;
  width: 100%;
  margin: 20px 0;
  padding: 25px 0;
}
  
.leftSiteAboutUs,
.rightSiteAboutUs {
  width: 70%;
  padding: 25px 80px;
}

.leftSiteAboutUs p,
.rightSiteAboutUs p {
  color: white;
}

  
.leftSiteAboutUs {
  border-top-right-radius: 37px;
  border-bottom-right-radius: 37px;
  margin-right: auto;
  text-align: left;
  margin-bottom: 20px;
}
  
.rightSiteAboutUs {
  border-top-left-radius: 37px;
  border-bottom-left-radius: 37px;
  margin-left: auto;
  text-align: right;
}
  
.logosAboutUs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* text-align: center; */
  margin: 20px 0;
}

.imgAboutUs {
  width: 120px;
  height: auto;
}

.imageContainerAboutUs {
  display: flex;
  align-items: center;
  flex-direction: column;
}
  
.logosAboutUs h1 {
  margin: 0;
}
  
@media (max-width: 768px) {
  .leftSiteAboutUs,
  .rightSiteAboutUs {
    width: 85%;
    padding: 20px 20px;
  }

  .imgAboutUs {
    width: 100px;  
  }
}

@media (max-width: 425px) {
  .imgAboutUs {
    width: 80px;
  }

  .imageDescriptionAboutUs {
    font-size: 10px;
  }
}
  

  