.activeBusLineContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1.5px solid;
}

.activeLineCardImage {
  width: 50px;
  height: auto;
}

.activeBusCardTitleText {
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

.activeBusCardDescriptionText {
  margin-bottom: 5px;
  margin-top: 0;
  font-size: 14px;
  color: grey;
  padding: auto;
}

.activeBusCardNextStopText {
  font-weight: 500;
  border: 1px solid;
  padding: 5px 10px;
  font-size: 16px;
}

.blinkingImageActiveBus {
  animation: blink 1s infinite;
  width: 65px;
  height: 22px;
  margin-left: 10px;
}

.nextStopconteiner {
  border-radius: 10px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 426px) {
  .activeBusLineContainer {
    display: grid;
    grid-template-columns: 45px 1fr;
    border: 1px solid;
    padding: 15px 15px;
  }

  .blinkingImageActiveBus {
    width: 50px;
    height: 15px;
    margin-left: 10px;
  }

  .activeBusCardTitleText {
    font-size: 18px;
  }

  .activeBusCardDescriptionText {
    font-size: 12px;
  }

  .activeBusCardNextStopText {
    font-size: 12px;
  }

  .activeLineCardImage {
    width: 50px;
    height: auto;
  }
}

@media (max-width: 780px) {
  .activeBusLineContainer {
    display: grid;
    grid-template-columns: 45px 1fr;
    border: 1px solid;
    padding: 15px 15px;
  }
}
