.busStopContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1.5px solid;
}

.imgBusStopCard {
  width: 60px;
  height: auto;
}

.titleNumbers {
  border-radius: 10px;
  color: white;
  padding: 0 15px;
  margin: 0;
  margin-right: 5px;
  font-size: 16px;
}

.navigateToGoogleMapsButton {
  border: 0;
  background-color: transparent;
  padding: 10px 5px 10px 10px;
  margin-right: 10px;
}

.navigateToGoogleMapsImage {
  width: 35px;
  height: 35px;
}

.stopTitle {
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

.locationCalculation {
  text-align: left;
  width: 100%;
  color: grey;
  font-size: 15px;
}

.lineContainer {
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 426px) {
  .titleNumbers {
    padding: 2px 13px;
  }

  .imgBusStopCard {
    width: 45px;
    height: auto;
  }

  .stopTitle {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .locationCalculation {
    font-size: 12px;
  }

  .navigateToGoogleMapsImage {
    width: 30px;
    height: 30px;
  }

  .busStopContainer {
    padding: 15px 15px;
    border: 1px solid;
  }
}
