.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleContentInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 25px;
    color: rgb(90, 90, 90);
  }
}

.titleLineNumbers {
  border-radius: 10px;
  color: white;
  padding: 0 15px;
  margin: 0;
  margin-right: 5px;
  font-size: 16px;
}

.scheduledNumbers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
}

.timeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.inactiveNumbers {
  border-radius: 10px;
  margin: 0;
  margin-right: 8px;
  padding: 5px 10px;
  font-size: 16px;
}

.inactiveNumbersCollapse {
  border-radius: 10px;
  margin: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 5px 10px;
  font-size: 16px;
}

.activeBusContainer {
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  margin: 0;
  margin-right: 10px;
  font-size: 16px;
}

.img {
  width: 50px;
  height: auto;
}

.busStopDetailsCard {
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 25px 30px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  border: 1.5px solid;
}

.busStopDetailsCardInnerContainer {
  display: flex;
  align-items: center;
}

.lineName {
  margin: 0;
  font-size: 19px;
  font-weight: bold;
}

.leftSide {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.arrow {
  font-size: 40px;
  cursor: pointer;
  margin-left: auto;
  padding: 20px;
  transition: transform 0.3s ease;
  display: inline-block;
}

.arrow.up {
  transform: rotate(180deg);
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow:hover {
  opacity: 0.8;
  cursor: pointer;
}

.collapseSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.collapseSectionInner {
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 15px;
  padding-left: 44px;
}

.customButton {
  border: 0;
  background-color: transparent;
  padding: 15px 15px 0 15px;
  font-weight: bold;
  width: 100%;
}

.blinkingImage {
  animation: blink 1s infinite;
  width: 65px;
  height: 22px;
  margin: 5px;
  margin-bottom: 10px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 448px) {
  .scheduledNumbers {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .timeContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
    flex-wrap: wrap;
  }

  .collapseSectionInner {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-left: 5px;
    margin-right: 0px;
  }

  .inactiveNumbersCollapse {
    border-radius: 10px;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 3px;
  }

  .inactiveNumbers {
    border-radius: 10px;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .img {
    width: 40px;
    height: auto;
  }

  .busStopDetailsCard {
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 10px 15px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .activeBusContainer {
    border-radius: 10px;
    color: white;
    margin: 0;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .blinkingImage {
    width: 50px;
    height: 15px;
  }

  .lineName {
    font-size: 16px;
  }

  .arrow {
    padding-left: 0;
    padding-right: 0px;
  }
}
