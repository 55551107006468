@import "leaflet/dist/leaflet.css";
/* CSS RESETS */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #F3F3F3;
}

h1,
h2,
h3,
p,
ul,
ol {
  margin: 0;
  margin-top: 0;
  margin-bottom: 0;
}

img {
  max-width: 100%;
}


