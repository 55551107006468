/* .navbar {
    display: flex;
    align-items: center;
    height: 90px;
    justify-content: space-between;
    padding: 10px 25px;
    background-color: #f8f9fa;
    position: fixed; 
    top: 0; 
    left: 0;
    width: 100%; 
    z-index: 10; 
}

.nav-link:hover {
    color: white;
}

.customActive p {
    color: white;
}

.customCollapse {
    position: absolute;
    width: 100%;
    top: 90px;
    left: 0;
    padding: 20px 30px;
}

.navbar-input-container {
    width: 100%;
    text-align: center;
    margin-top: 10px;
}
  
.navbar-input {
    width: 90%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
}
 */

 .titleLineDetails {
    position: relative;
    z-index: 2;
  }

 .navbar {
    display: flex;
    align-items: center;
    /* height: 90px; */
    justify-content: space-between;
    padding: 8px 0px 8px 0px;
    background-color: #f8f9fa;
    position: fixed; 
    top: 0; 
    left: 0;
    width: 100%; 
    z-index: 10;
    flex-wrap: wrap;
}

.headerLogoStyle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
}

.headerLogo {
    width: 45px;
    height: 45px;
}

.navbar-collapse {
    flex-grow: 1;
    justify-content: center;
}

.nav-link:hover {
    color: white;
}

.customActive p {
    color: white;
}

.customCollapse {
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;
    padding: 0 20px;
    padding-bottom: 15px;
    background-color: #0F9565;
}

.formConteinerHeader {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column-reverse;
    padding-left: 70px;
}

.formConteinerHeader input {
    width: 150px;
}

.customFormStyle {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
}

.customInput {
    width: 150px;
    border: 1px solid black;
    border-radius: 20px;
    padding: 3px 5px;
}

@media (max-width: 425px ) {
    .headerLogo {
        width: 45px;
        height: 45px;
    }

    .h5HeaderLogo {
        font-size: 15px;
        margin-bottom: 0;
    }
}
