.timeline-container {
  overflow: hidden;
}

.item-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.time-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: lightgrey;
  padding: 5px;
}

.imgTimelineItem {
  width: 45px;
  height: auto;
  margin-right: 10px;
}

.timeline-connector {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  z-index: 1;
}

.line {
  position: absolute;
  top: 5px;
  width: 3px;
  background-color: #3b3938;
}

.dark-line {
  background-color: darkgrey;
}

.short-line {
  height: 98px;
  margin-left: 5px;
}

.item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: beige;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.passed-stop {
  opacity: 0.5;
}

.text-container {
  display: flex;
}

.text-container-inner {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.item-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}

.next-times {
  display: flex;
  gap: 5px;
}

.time-box {
  padding: 5px;
  border-radius: 10px;
}

@media (max-width: 426px) {
  .imgTimelineItem {
    width: 35px;
    height: auto;
    margin-right: 5px;
    margin: auto 5px auto 0;
  }

  .item-title {
    font-size: 16px;
  }

  .timelineTimeLeft {
    font-size: 14px;
  }

  .time-box p {
    font-size: 14px;
    text-align: center;
  }

  .short-line {
    height: 85px;
    margin-left: 5px;
  }
}
