.navigateToLineScreenButton {
  margin: auto;
  display: block;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

@media (max-width: 768px) {
  .marginBottomButton {
    margin-bottom: 5px;
  }
}
