.titleLineDetails {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.calendarButton {
    position: absolute;
    top: 10px;
    right: 20px;
    border: 0;
    background-color: transparent;
}

.calendarImage {
    width: 30px;
    height: auto;
}

@media (max-width: 321px) {
    .calendarButton {
        top: 5px;
        right: 0;
    }
}