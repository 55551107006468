.footer_bg {
    position: relative;
    bottom: 0;
    background: url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEigB8iI5tb8WSVBuVUGc9UjjB8O0708X7Fdic_4O1LT4CmLHoiwhanLXiRhe82yw0R7LgACQ2IhZaTY0hhmGi0gYp_Ynb49CVzfmXtYHUVKgXXpWvJ_oYT8cB4vzsnJLe3iCwuzj-w6PeYq_JaHmy_CoGoa6nw0FBo-2xLdOPvsLTh_fmYH2xhkaZ-OGQ/s16000/footer_bg.png") no-repeat scroll center 0;
    width: 100%;
    height: 266px;
    overflow: hidden;
}

.footer_bg .footer_bg_one {
    background: url("https://i.giphy.com/iEAoZGHotpssZJpjwQ.webp") no-repeat center center;
    width: 310px;
    height: 105px;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: -310px;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.footer_bg .footer_bg_two {
    background: url("https://i.giphy.com/WpNnDZucS2W1DoD66e.webp") no-repeat center center;
    width: 320px;
    height: 105px;
    background-size: 100%;
    bottom: 0;
    left: -320px;
    position: absolute;
    -webkit-animation: myfirst 15s linear infinite;
    animation: myfirst 15s linear infinite;
}

@-moz-keyframes myfirst {
    0% {
        left: -310px;
        /* Start outside the viewport */
    }
    100% {
        left: 100%;
        /* End outside the viewport */
    }
}

@-webkit-keyframes myfirst {
    0% {
        left: -310px;
    }
    100% {
        left: 100%;
    }
}

@keyframes myfirst {
    0% {
        left: -310px;
    }
    100% {
        left: 100%;
    }
}

@media only screen and (max-width: 640px) {
    .footer_bg .footer_bg_one {
        -webkit-animation: myfirst 5s linear infinite;
        animation: myfirst 5s linear infinite;
    }
    .footer_bg .footer_bg_two {
        -webkit-animation: myfirst 1s linear infinite;
        animation: myfirst 1s linear infinite;
    }
}

@media only screen and (max-width: 768px) {
    .footer_bg .footer_bg_one {
        -webkit-animation: myfirst 13s linear infinite;
        animation: myfirst 13s linear infinite;
    }
    .footer_bg .footer_bg_two {
        -webkit-animation: myfirst 7s linear infinite;
        animation: myfirst 7s linear infinite;
    }
}