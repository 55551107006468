
.loading-page {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 min-height: 100vh;
}
  
.spinner {
 width: 70px;
 height: 70px;
 border: 6px solid rgba(0, 0, 0, 0.1);
 border-top: 6px solid;
 border-radius: 50%;
animation: spin 1s linear infinite;
}
  

p {
 margin-top: 20px;
 font-size: 18px;
 color: #333;
}
  
@keyframes spin {
 to {
   transform: rotate(360deg);
 } 
}
  